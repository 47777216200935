// export const baseURL = "https://empi.azurewebsites.net/api";
export const baseURL="https://nextdriver.azurefd.net/api"
export const oidcBaseURL = "https://nextdriver.azurefd.net/oidc";

export const channels = [
  {
    channelId: "998ccd84-4d67-4126-c283-08d896d1763a",
    channelType: 1,
    number: "0031634462949", // WhatsApp
  },
  {
    channelId: "a20781df-d774-4dbd-b894-faf97aac3884",
    channelType: 2, // In-app
    number: null,
  },
];
